import React from "react";

function Created() {
  return (
    <>
      <div>Formulario criado com sucesso</div>
    </>
  );
}

export default Created;
