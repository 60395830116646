import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import App from "./app";
import Created from "./pages/created";
import RepliedForm from "./pages/repliedForm";
import ViewForm from "./pages/viewForm";
import Update from "./update";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/update/:publicKey" element={<Update />}></Route>
        <Route path="/created" element={<Created />}></Route>
        <Route path="/view-form" element={<ViewForm />}></Route>
        <Route
          path="/replied-form/:publicKey"
          element={<RepliedForm />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
