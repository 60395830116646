import { ReactFormGenerator } from "@appsafetyhub/safetyhub-form-builder";
import React, { useState } from "react";
import Loading from "../loading";

function RepliedForm() {
  const [dataJSON, setDataJSON] = useState(null);

  window.addEventListener("message", function (e) {
    setDataJSON(JSON.parse(e.data));
  });

  return (
    <>
      <div style={{ padding: "40px" }}>
        {dataJSON !== null ? (
          <ReactFormGenerator
            download_path=""
            back_action="/"
            answer_data={JSON.parse(dataJSON.resultData)}
            action_name="Save"
            form_action="/"
            form_method="POST"
            read_only={true}
            hide_actions={true}
            data={JSON.parse(dataJSON.auditForm_formData)}
          />
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}

export default RepliedForm;
