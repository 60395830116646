import { ReactFormBuilder } from "@appsafetyhub/safetyhub-form-builder";
import { useState } from "react";
import { items } from "./customizedItems";
import Loading from "./loading";

function Update() {
  const [dataReceived, setDataReceived] = useState(null);

  const onPost = (data) => {
    window.parent.postMessage(JSON.stringify(data.task_data), `*`);
  };

  window.addEventListener("message", function (e) {
    if (e.data) {
      setDataReceived(JSON.parse(e.data));
    }
  });

  return (
    <div className="App" style={{ width: "100%", backgroundColor: "#1e1e2d" }}>
      {dataReceived && dataReceived.result ? (
        <ReactFormBuilder
          data={JSON.parse(dataReceived.result)}
          onPost={onPost}
          toolbarItems={items}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Update;
