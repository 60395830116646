import imageCompression from "browser-image-compression";
import React, { useState } from "react";
import {Registry} from "@appsafetyhub/safetyhub-form-builder";

export const UploadImage = React.forwardRef((props, ref) => {
    const { name, defaultValue, disabled } = props;
  
    const [state, setState] = useState(null);
    const [type, setType] = useState("file");
  
    const [isLoading, setIsLoading] = useState(false);
  
    return (
      <>
        {defaultValue && defaultValue ? (
          <>
            <img
              src={defaultValue}
              style={{
                width: "300px",
                height: "300px",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              onChange={async (e) => {
                setIsLoading(true);
                const options = {
                  maxSizeMB: 0.1,
                };
                try {
                  const compressedFile = await imageCompression(
                    e.target.files[0],
                    options
                  );
  
                  const blobToBase64 = (blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    return new Promise((resolve) => {
                      reader.onloadend = () => {
                        resolve(reader.result);
                      };
                    });
                  };
  
                  blobToBase64(compressedFile).then((res) => {
                    setState(res);
                  });
  
                  // const formData = new FormData();
                  // formData.append("file", compressedFile);
                  // formData.append("upload_preset", "safetyhub-upload");
  
                  // const data = await fetch(
                  //   "https://api.cloudinary.com/v1_1/sanchez1321/image/upload",
                  //   {
                  //     method: "POST",
                  //     body: formData,
                  //   }
                  // ).then((r) => r.json());
  
                  setType("text");
                  // setState(data.url);
                  setIsLoading(false);
                } catch (error) {
                  console.log(error);
                }
              }}
              style={{ opacity: isLoading || type === "text" ? "0" : "1" }}
              type={type}
              ref={ref}
              value={state}
              name={name}
              defaultValue={defaultValue}
              disabled={isLoading}
              accept="image/*"
            />
            {isLoading === true && (
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
            {state !== null && !isLoading && (
              <div className="d-flex flex-column align-items-start justify-content-start">
                <img
                  src={state}
                  style={{
                    width: "300px",
                    height: "300px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
                <button
                  onClick={() => {
                    setType("file");
                    setState(null);
                  }}
                  style={{backgroundColor: '#ff6816', color: 'white', width: '300px', marginTop: '6px'}}
                  className="btn"
                >
                  Excluir arquivo
                </button>
              </div>
            )}
          </div>
        )}
      </>
    );
});

Registry.register("UploadImage", UploadImage);

export const items = [
  {
    key: "Header",
  },
  {
    key: "Label",
  },
  {
    key: "Paragraph",
  },
  {
    key: "LineBreak",
  },
  {
    key: "Dropdown",
  },
  {
    key: "Tags",
  },
  {
    key: "Checkboxes",
  },
  {
    key: "RadioButtons",
  },
  {
    key: "TextInput",
  },
  {
    key: "EmailInput",
  },
  {
    key: "NumberInput",
  },
  {
    key: "PhoneNumber",
  },
  {
    key: "TextArea",
  },
  {
    key: "TwoColumnRow",
  },
  {
    key: "ThreeColumnRow",
  },
  {
    key: "Rating",
  },
  {
    key: "DatePicker",
  },
  {
    key: "Signature",
  },
  {
    key: "HyperLink",
  },
  {
    key: "Range",
  },
  {
    key: "Image",
  },
  {
    key: "UploadImage",
    element: "CustomElement",
    component: UploadImage,
    type: "custom",
    forwardRef: true,
    field_name: "upload_image_",
    name: "Upload Image",
    icon: "fa fa-upload",
    props: { test: "test_input" },
    label: "Label Input",
  },
];

export const itemsPT = [
  {
    name: "Titulo",
    key: "Header",
  },
  {
    key: "Label",
  },
  {
    name: "Paragrafo",
    key: "Paragraph",
  },
  {
    key: "LineBreak",
    name: "Quebra de linha"
  },
  {
    key: "Dropdown",
  },
  {
    key: "Tags",
  },
  {
    key: "Checkboxes",
  },
  {
    key: "RadioButtons",
    name: "Botões de radio"
  },
  {
    key: "TextInput",
    name: "Texto"
  },
  {
    key: "EmailInput",
    name: "Email"
  },
  {
    key: "NumberInput",
    name: "Número"
  },
  {
    key: "PhoneNumber",
    name: "Telefone"
  },
  {
    key: "TextArea",
    name: "Area de texto"
  },
  {
    key: "TwoColumnRow",
    name: "Duas colunas"
  },
  {
    key: "ThreeColumnRow",
    name: "Três colunas"
  },
  {
    key: "Rating",
    name: "Avaliação"
  },
  {
    key: "DatePicker",
    name: "Data"
  },
  {
    key: "Signature",
    name: "Assinatura"
  },
  {
    key: "HyperLink",
    name: "Link"
  },
  {
    key: "Range",
  },
  {
    key: "Image",
    name: "Imagem"
  },
  {
    key: "UploadImage",
    element: "CustomElement",
    component: UploadImage,
    type: "custom",
    forwardRef: true,
    field_name: "upload_image_",
    name: "Upload de imagem",
    icon: "fa fa-upload",
    props: { test: "test_input" },
    label: "Label Input",
  },
];