import { ReactFormGenerator } from "@appsafetyhub/safetyhub-form-builder";
import { useEffect, useState } from "react";
import Loading from "../loading";

function ViewForm() {
  const [dataJSON, setDataJSON] = useState(null);

  useEffect(() => {
    window.addEventListener("message", function (e) {
      setDataJSON(JSON.parse(e.data));
    });
  }, []);

  const handleSubmitForm = (data) => {
    window.parent.postMessage(JSON.stringify(data), `*`);
  };

  return (
    <div style={{ padding: "40px" }}>
      {dataJSON !== null ? (
        <ReactFormGenerator
          download_path=""
          back_action="/"
          answer_data={{}}
          action_name="Save"
          form_action="/"
          form_method="POST"
          read_only={false}
          onSubmit={handleSubmitForm}
          hide_actions={false}
          data={dataJSON}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default ViewForm;
