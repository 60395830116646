import React from 'react'
import './loading.css'

const Loading = () => {
  return (
    <>
        <div className={`loading`}>
            <img src={'/media/logos/logo.png'} alt='Start logo' />
        </div>
    </>
  )
}

export default Loading